import { css, styled } from '@creditas/stylitas'
import { media } from '@creditas-lab/style-utilities'

import { backgroundTokens, sizeList } from './constants'

const setSize = token => sizeList[token] || 0

const setBackgroundColor = (theme, token) => {
  return (
    theme[backgroundTokens[token]] || backgroundTokens[token] || 'transparent'
  )
}

const devices = {
  mobile: css`
    ${media.md`
      display: none;
    `}
  `,
  desktop: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
}

const Wrapper = styled.div(
  ({ backgroundColor, size, theme, visibleIn }) => css`
    height: ${setSize(size)};
    background-color: ${setBackgroundColor(theme, backgroundColor)};

    ${devices[visibleIn] && devices[visibleIn]}
  `,
)

export { Wrapper }
