import React from 'react'
import { oneOf } from 'prop-types'

import { Wrapper } from './styles'

const propTypes = {
  backgroundColor: oneOf([
    'Primary 40',
    'Primary 60',
    'Primary 80',
    'Secondary 20',
    'Secondary 40',
    'Secondary 60',
    'Neutral 0',
    'Neutral 10',
    'Neutral 15',
    'Neutral 20',
    'Neutral 40',
    'Neutral 60',
    'Neutral 80',
    'Neutral 90',
    'Neutral 100',
  ]),
  size: oneOf(['8XL', '12XL', '16XL']),
  visibleIn: oneOf(['mobile', 'desktop', 'both']),
}

const defaultProps = {
  visibleIn: 'both',
}

const Spacing = ({ backgroundColor, size, visibleIn, ...others }) =>
  size ? (
    <Wrapper
      data-testid="section-spacing"
      size={size}
      backgroundColor={backgroundColor}
      visibleIn={visibleIn || defaultProps.visibleIn}
      {...others}
    />
  ) : null

Spacing.propTypes = propTypes
Spacing.defaultProps = defaultProps

export default Spacing
