const backgroundTokens = {
  'Primary 40': 'colorBackgroundLight',
  'Primary 60': 'colorBackgroundDefault',
  'Primary 80': 'colorBackgroundDark',
  'Secondary 20': 'colorBrandSecondaryLight',
  'Secondary 40': 'colorBrandSecondaryDefault',
  'Secondary 60': '#1661B8',
  'Neutral 0': 'colorBackgroundWhite',
  'Neutral 10': 'colorNeutral10',
  'Neutral 15': 'colorNeutral15',
  'Neutral 20': 'colorNeutral20',
  'Neutral 40': 'colorNeutral40',
  'Neutral 60': 'colorNeutral60',
  'Neutral 80': 'colorNeutral80',
  'Neutral 90': 'colorNeutral90',
  'Neutral 100': 'colorNeutral100',
}

export { backgroundTokens }
